export const isUserSignedIn = () => {
    const token = localStorage.getItem('access_token');
    if (!token) return false; // No token, user is not signed in

    try {
        // Decode the token to check for expiration (optional)
        const payload = JSON.parse(atob(token.split('.')[1]));
        const now = Math.floor(Date.now() / 1000);
        return payload.exp > now; // Check if token has not expired
    } catch (error) {
        console.error("Error decoding token", error);
        return false; // Invalid token
    }
};