import React, { useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
// Define Protected Route Props
interface ProtectedRouteProps {
    element: React.ReactElement;
  }

// Protected Route Component
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const { isTokenExpired, refreshToken, logout } = useAuth();
    const [isAuthenticated, setIsAuthenticated] = useState(true); // Track authentication status


    useEffect(() => {
      const verifyToken = async () => {
          if (isTokenExpired()) {
            console.log("Token is expired");
              try {
                  const token = await refreshToken();
                  console.log(token);
                  if (token) {
                      setIsAuthenticated(true);
                      window.location.reload();
                  } else {
                    logout();
                      setIsAuthenticated(false);
                  }
              } catch (error) {
                  console.error('Token refresh failed', error);
                  logout();
                  setIsAuthenticated(false);
              }
          } else {
            console.log("Token is not expired");
              setIsAuthenticated(true);
          }
      };

      verifyToken();
  }, [isTokenExpired]);

    if (!isAuthenticated) {
        console.log("Redirecting to login")
      // If the user is not authenticated, redirect to the login page
      return <Navigate to="/login" replace />;
    }
  
    // If the user is authenticated, render the requested page
    return element;
  };
  
  export default ProtectedRoute;
