import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Spinner = ({ courses }: { courses: string[] }) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 1) % courses.length);
    }, 3000); // Change course every 2 seconds
    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [courses.length]);

  return (
    <div
      style={{
        position: "relative",
        height: "100px", // Adjust as needed
        overflow: "hidden",
        textAlign: "center",
      }}
    >
      <AnimatePresence>
        {courses.map((course, index) =>
          visibleIndex === index ? (
            <motion.div
              key={course}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 1 }}
              style={{
                position: "absolute",
                width: "100%",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              {course}
            </motion.div>
          ) : null
        )}
      </AnimatePresence>
    </div>
  );
};

export default Spinner;
