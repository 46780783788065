import React from 'react';
import { Container, Typography, Box, Paper, Link } from '@mui/material';

const TermsAndConditions: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Typography variant="h4" gutterBottom>
            Terms & Conditions
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            Last Updated: [10/25/2024]
          </Typography>

          {/* Section 1: Introduction */}
          <Typography variant="h6" gutterBottom>
            1. Introduction
          </Typography>
          <Typography>
            Welcome to Quick Tee! By using our service, you agree to these Terms & Conditions.
            Please read them carefully. If you do not agree, do not use our service.
          </Typography>

          {/* Section 2: Service Description */}
          <Typography variant="h6" gutterBottom>
            2. Service Description
          </Typography>
          <Typography>
            Quick Tee provides SMS notifications about available tee times based on your
            preferences. These notifications may include promotional content, reminders, and
            updates relevant to your preferences.
          </Typography>

          {/* Section 3: Opt-in Consent */}
          <Typography variant="h6" gutterBottom>
            3. Opt-in Consent
          </Typography>
          <Typography>
            By registering, you consent to receive SMS notifications from Quick Tee at the phone
            number provided during registration. You may receive up to two messages per week.
            Message and data rates may apply.
          </Typography>

          {/* Section 4: Message Frequency */}
          <Typography variant="h6" gutterBottom>
            4. Message Frequency
          </Typography>
          <Typography>
            You can expect to receive up to 5 SMS notifications per week. The frequency may vary
            depending on the availability of tee times based on your selected preferences.
          </Typography>

          {/* Section 5: Fees and Charges */}
          <Typography variant="h6" gutterBottom>
            5. Fees and Charges
          </Typography>
          <Typography>
            Standard message and data rates may apply based on your mobile carrier's plan. Please
            consult your mobile provider for information regarding fees.
          </Typography>

          {/* Section 6: User Responsibilities */}
          <Typography variant="h6" gutterBottom>
            6. User Responsibilities
          </Typography>
          <Typography>
            You are responsible for providing accurate information when registering for Quick Tee.
            You agree not to use the service for any unlawful purposes and to comply with all
            applicable laws.
          </Typography>

          {/* Section 7: Opt-Out Instructions */}
          <Typography variant="h6" gutterBottom>
            7. Opt-Out Instructions
          </Typography>
          <Typography>
            To stop receiving SMS notifications from Quick Tee, you can reply with "STOP" to any
            message received. You will receive a confirmation message that you have successfully
            opted out.
          </Typography>

          {/* Section 8: Modifications to the Service */}
          <Typography variant="h6" gutterBottom>
            8. Modifications to the Service
          </Typography>
          <Typography>
            Quick Tee reserves the right to modify, suspend, or discontinue the service at any
            time without prior notice. We are not liable for any consequences arising from such
            modifications.
          </Typography>

          {/* Section 9: Termination */}
          <Typography variant="h6" gutterBottom>
            9. Termination
          </Typography>
          <Typography>
            Quick Tee may terminate your access to the service at any time if you violate these
            Terms & Conditions or engage in any behavior that is unlawful or inappropriate.
          </Typography>

          {/* Section 10: Limitation of Liability */}
          <Typography variant="h6" gutterBottom>
            10. Limitation of Liability
          </Typography>
          <Typography>
            Quick Tee is not liable for any direct, indirect, incidental, or consequential damages
            arising from the use or inability to use our service. The service is provided "as is"
            without any warranties.
          </Typography>

          {/* Section 11: Governing Law */}
          <Typography variant="h6" gutterBottom>
            11. Governing Law
          </Typography>
          <Typography>
            These Terms & Conditions are governed by and construed in accordance with the laws of
            Arizona/United States of America. Any disputes arising from these terms will be subject to the
            exclusive jurisdiction of the courts in Arizona/United States of America.
          </Typography>

          {/* Section 12: Contact Information */}
          <Typography variant="h6" gutterBottom>
            12. Contact Information
          </Typography>
          <Typography>
            If you have any questions about these Terms & Conditions, please contact us at:
          </Typography>
          <Typography>
            Email: <Link href="mailto:contact@quick-tee.com">contact@quick-tee.com</Link>
          </Typography>

          {/* Section 13: Changes to Terms & Conditions */}
          <Typography variant="h6" gutterBottom>
            13. Changes to Terms & Conditions
          </Typography>
          <Typography>
            Quick Tee reserves the right to update these Terms & Conditions at any time. Any
            changes will be posted on this page, and the "Last Updated" date will be modified
            accordingly. We encourage you to review this page periodically.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
