import { useEffect, useState } from 'react';
import {
    CircularProgress,
    Typography, Grid2, Box,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions
} from '@mui/material';

import Subscribe from '../components/Subscribe';
import FilterForm from '../components/FilterForm';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FilterComponent from '../components/Filter';

import { FilterType } from '../types/FilterType'
import apiClient from '../utils/apiClient';

const FiltersList = () => {
    const [filters, setFilters]: any = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editingFilter, setEditingFilter] = useState<any>(null); // Store the filter being edited
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const theme = useTheme();

    const [hasSubscription, setHasSubscription] = useState(false); // Track subscription status
    const [newFilter, setNewFilter] = useState<FilterType>({
        num_of_players: 0,
        course: '',
        date_start: '',
        date_end: '',
        days_of_week: '',
        time_gte: '',
        time_lte: '',
        size: -1,
        user: ''
    });

    const fetchFilters = async () => {
        try {
            const response = await apiClient.get('/filters/');
            setFilters(response.data);
        } catch (error) {
            setError('Failed to load filters');
        }
    };


    const fetchAccountData = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get('/account/');
            const { status } = response.data; // Extract subscription ID from response
            setHasSubscription(status == 'active' || status == 'trialing'); // Set to true if subscription ID is not null
        } catch (error) {
            console.error('Error fetching account data:', error);
        }
        setLoading(false);
    };

    const openCreateForm = () => {
        setIsEditing(false); // Create mode
        setNewFilter({
            num_of_players: 0,
            course: '',
            date_start: '',
            date_end: '',
            days_of_week: '',
            time_gte: '',
            time_lte: '',
            size: -1,
            user: ''
        });
        setOpen(true);
    };

    const openEditForm = (filter: any) => {
        setIsEditing(true);  // Edit mode
        setEditingFilter(filter);
        setNewFilter({
            num_of_players: filter.num_of_players,
            course: filter.course,
            date_start: filter.date_start ? filter.date_start : '',
            date_end: filter.date_end ? filter.date_end : '',
            days_of_week: filter.days_of_week ? filter.days_of_week : '',
            time_gte: filter.time_gte ? filter.time_gte : '',
            time_lte: filter.time_lte ? filter.time_lte : '',
            size: filter.size,
            user: filter.user
        });
        setOpen(true);
    };

    const handleDeleteFilter = async (filterId: number) => {
        try {
            const response = await apiClient.delete(`/filters/${filterId}/`);
            setFilters(filters.filter((filter: any) => filter.id !== filterId));
        } catch (err) {
            setError('Failed to delete filter');
        }
    };

    const handleCreateFilter = async () => {
        try {
            const response = await apiClient.post('/filters/create/', newFilter);
            // Add the new filter to the list
            setFilters([...filters, response.data]);
            setOpen(false); // Close the modal
        } catch (err) {
            setError('Failed to create filter');
        }
    };

    // Handle closing the modal
    const handleClose = () => {
        setOpen(false);
        setEditingFilter(null);
    };

    const handleEditFilter = async () => {
        try {
            const response = await apiClient.put(`/filters/${editingFilter.id}/`, newFilter);
            // Update the filters list
            setFilters(filters.map((f: any) => (f.id === editingFilter.id ? response.data : f)));
            setOpen(false);  // Close modal
            // fetchFilters();
        } catch (err) {
            setError('Failed to edit filter');
        }
    };

    useEffect(() => {
        fetchFilters();
        fetchAccountData();
    }, []);

    // Display loading spinner if data is still being fetched
    if (loading) {
        return <CircularProgress />;
    }

    // Display error message if API request fails
    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4} p={3}>
                <Typography variant="h4">My Filters</Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={openCreateForm}
                >
                    Create New Filter
                </Button>
            </Box>

            {!loading && !hasSubscription && <Box display="flex" alignItems="center" justifyContent="center"
                sx={{ bgcolor: 'secondary.main', color: 'white', p: 2, borderRadius: 2, m: 3 }}>
                <Typography>Note: Filters will not work until you have an active subscription. Click here to sign up:</Typography>
                <Subscribe />
            </Box>}


            {filters.length > 0 ? (
                <Box display="flex" p={6}>
                    <Grid2 container spacing={3}>
                        {filters.map((filter: any) => (
                            <FilterComponent
                                filter={filter}
                                openEditForm={openEditForm}
                                handleDeleteFilter={handleDeleteFilter}
                                subscribed={hasSubscription}
                            />
                        ))}
                    </Grid2>
                </Box>

            ) : (
                <Typography>No filters found.</Typography>
            )}

            {/* Modal for editing filter */}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-paper': {
                        borderRadius: '16px', // Rounded corners
                    },
                    '& .MuiDialogTitle-root': {
                        bgcolor: theme.palette.primary.main, // Green background
                        color: 'white', // White text color
                        borderTopLeftRadius: '16px', // Rounded corners for top left
                        borderTopRightRadius: '16px', // Rounded corners for top right
                    }
                }}
            >
                <DialogTitle>{isEditing ? 'Edit Filter' : 'Create New Filter'}</DialogTitle>
                <DialogContent>
                    <FilterForm
                        newFilter={newFilter}
                        setNewFilter={setNewFilter}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={isEditing ? handleEditFilter : handleCreateFilter} color="primary">
                        {isEditing ? 'Save Changes' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FiltersList;
