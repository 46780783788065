import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import apiClient from '../utils/apiClient';
import { Button, useMediaQuery } from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { styled, useTheme } from '@mui/material/styles';
import { isUserSignedIn } from '../utils/authUtils';
import { useNavigate } from 'react-router-dom';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const PreregisterButton = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handlePreRegister = async () => {
    setLoading(true);
    setErrorMessage("");
    if (!isUserSignedIn()) {
      console.log("User not signed in, redirecting to login.");
      navigate('/register', { state: { origin: 'preregister' } });
    } else {
      try {
        // Call your backend to create a Checkout session
        const response = await apiClient.post('/preregister/');

        const stripe = await stripePromise;
        await stripe!.redirectToCheckout({
          sessionId: response.data.id,
        });
      } catch (error: any) {
        setErrorMessage(error.response?.data?.error || error.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    }
  };

  const FancyButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    padding: '12px 24px',
    fontSize: isMobile ? '12px' : '18px',
    fontWeight: 'bold',
    borderRadius: '50px',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 20px rgba(0,0,0,0.5)',
    },
  }));

  return (
    <div>
      {errorMessage && (
        <p style={{ color: 'red', marginBottom: '1em' }}>{errorMessage}</p>
      )}
      <FancyButton
        onClick={handlePreRegister}
        disabled={loading}
        endIcon={<EventNoteIcon />} // Adds an icon at the end
      >
        {loading ? "Processing..." : "Pre-register"}
      </FancyButton>
    </div>
  );
};

export default PreregisterButton;
