import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box my={4}>
                <Paper elevation={3} sx={{ padding: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Privacy Policy
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                        Last Updated: [10/25/2024]
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        1. Introduction
                    </Typography>
                    <Typography>
                        This Privacy Policy explains how Quick Tee collects, uses, discloses, and protects
                        your personal information when you use our service.
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        2. Information We Collect
                    </Typography>
                    <Typography>
                        We collect the following information when you register for our service:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>Contact Information: Name, email address, and phone number.</Typography>
                        </li>
                        <li>
                            <Typography>Preferences: Information related to your tee time preferences and golf course selections.</Typography>
                        </li>
                        <li>
                            <Typography>Usage Information: Interactions with our website, app, and SMS service.</Typography>
                        </li>
                    </ul>


                    <Typography variant="h6" gutterBottom>
                        3. How We Use Your Information
                    </Typography>
                    <Typography>
                        We use the information we collect to:

                        Provide and personalize our SMS notifications based on your preferences.
                        Send promotional messages, reminders, and updates about our service.
                        Improve and enhance our service through user feedback and analytics.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        4. Data Sharing
                    </Typography>
                    <Typography>
                        We do not sell, trade, or otherwise transfer your personal information to outside parties. We may share information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        5. Data Security
                    </Typography>
                    <Typography>
                        We implement a variety of security measures to protect your personal information from unauthorized access, disclosure, or misuse. These measures include encryption, secure servers, and access controls.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        6. Cookies
                    </Typography>
                    <Typography>
                        We may use cookies to improve your experience on our website. Cookies are small files stored on your computer's hard drive that help us analyze web traffic and improve our service. You can choose to accept or decline cookies.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        7. User Rights
                    </Typography>
                    <Typography>
                        You have the right to:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>Access the personal data we hold about you.</Typography>
                        </li>
                        <li>
                            <Typography>Request corrections to your personal information.</Typography>
                        </li>
                        <li>
                            <Typography>Opt-out of SMS notifications by replying "STOP" or by contacting us.</Typography>
                        </li>
                        <li>
                            <Typography>Request the deletion of your personal information.</Typography>
                        </li>
                    </ul>

                    <Typography variant="h6" gutterBottom>
                        8. Children's Privacy
                    </Typography>
                    <Typography>
                        Quick Tee does not knowingly collect information from children under the age of 13. If we become aware that we have collected personal data from a child without parental consent, we will delete it.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        9. Third-Party Links
                    </Typography>
                    <Typography>
                        Our service may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        10. Changes to This Policy
                    </Typography>
                    <Typography>
                        We reserve the right to update this Privacy Policy at any time. Changes will be posted on this page, and your continued use of the service constitutes acceptance of any changes.
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        11. Contact Information
                    </Typography>
                    <Typography>
                        If you have any questions or concerns about this Privacy Policy, please contact us at:

                        <Typography>
                            Email: contact@quick-tee.com
                        </Typography>
                    </Typography>
                    {/* Add more sections similarly */}
                </Paper>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
