import React, { useState } from 'react';
import { Chip, TablePagination } from '@mui/material';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import { CourseRequest } from '../types/CourseRequest';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useTheme } from '@mui/material/styles';

const CourseRequestsTable = ({ courseRequests }: { courseRequests: CourseRequest[]}) => {
  const [courseRowsPerPage, setCourseRowsPerPage] = useState(3);
  const [coursePage, setCoursePage] = useState(0);

  const theme = useTheme();
  const handleCourseChangePage = (event: unknown, newPage: number) => setCoursePage(newPage);
  const handleCourseChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseRowsPerPage(parseInt(event.target.value, 10));
    setCoursePage(0);
  };

  const displayedRequests = courseRequests.slice(coursePage * courseRowsPerPage, coursePage * courseRowsPerPage + courseRowsPerPage);

  const statusMap = {
    reviewing: { label: 'Reviewing', color: theme.palette.warning.light },
    work_in_progress: { label: 'In Progress', color: theme.palette.info.main },
    rejected: { label: 'Rejected', color: theme.palette.error.main },
    added: { label: 'Added', color: theme.palette.success.main },
  } as const;

  return (
          <Card sx={{ display: 'flex', flexDirection: 'column', pt: 1, borderRadius: '20px' }}>
            <CardContent sx={{ p: 0 }}>
              <Typography variant="h6" sx={{ pl: 2, pt: 3, pb: 1 }}>Course Requests</Typography>
              {courseRequests.length > 0 ? (

                <TableContainer sx={{ flexGrow: 1, height: '100%' }}>
                  <Table aria-label="simple table" sx={{ height: '100%' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Status</TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedRequests.map((request: CourseRequest, index: any) => {
                        const { label, color } = statusMap[request.status] ?? { label: 'Unknown', color: 'default' };
                        return (

                          <TableRow
                            key={index}
                          >
                            <TableCell component="th" scope="row">
                              {request.name}
                            </TableCell>
                            <TableCell>
                              <Chip label={label} size="small"
                                sx={{
                                  backgroundColor: `${color}3A`,
                                  color: color,
                                  borderRadius: '8px',
                                  padding: '0 8px',
                                  fontWeight: 'bold'
                                }} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                  </Table>
                </TableContainer>

              ) : (
                <Typography sx={{textAlign:'center', color:'info.main'}}>No current requests</Typography>
              )}
              {courseRequests.length > 0 && <TablePagination
                component="div"
                count={courseRequests.length}
                page={coursePage}
                onPageChange={handleCourseChangePage}
                rowsPerPage={courseRowsPerPage}
                onRowsPerPageChange={handleCourseChangeRowsPerPage}
                rowsPerPageOptions={[]}
              />}
            </CardContent>
          </Card>
  );
};

export default CourseRequestsTable;
