import {
    TextField,
    Box,
    Modal,
    Button
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import apiClient from '../utils/apiClient';
import { useEffect, useState } from 'react';

interface FilterFormProps {
    newFilter: any;
    setNewFilter: (filter: any) => void;
    onNewCourseRequest?: () => void;
}

const CourseSearch: React.FC<FilterFormProps> = ({
    newFilter,
    setNewFilter,
    onNewCourseRequest
}) => {

    const [courses, setCourses] = useState([]);
    const [newCourse, setNewCourse] = useState({ name: '', location: '' });
    const [openModal, setOpenModal] = useState(false);
    const filter = createFilterOptions();
    const baseURL = process.env.REACT_APP_BACK_END_URL;


const fetchCourses = async () => {
    try {
        const response = await axios.get(`${baseURL}/courses/`, {
        });
        setCourses(response.data);
    } catch (error) {
        console.error("Error fetching courses:", error);
    }
};

const handleOpenModal = (course_name: string) => {
    setNewCourse({ name: course_name, location: '' });
    setOpenModal(true);
}
const handleSubmitNewCourse = async () => {
    try {
        const response = await apiClient.post('/course-request/', newCourse);
        if (onNewCourseRequest) {
            onNewCourseRequest();
        }
        handleCloseModal();
    } catch (err) {
        console.log("Failed to request course")
    }
};

const handleCloseModal = () => {
    setOpenModal(false)
};

useEffect(() => {
    fetchCourses();
}, []);

return (
    <div>
        <Autocomplete
            options={courses}
            getOptionLabel={(course: any) => {
                if (typeof course === 'string') {
                    return course;
                }
                if (course.inputValue) {
                    return course.inputValue;
                }
                return course.formatted_name;
            }}
            value={courses.find((course: any) => course.id === newFilter.course) || null}
            onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                    handleOpenModal(newValue.inputValue);
                } else if (newValue) {
                    setNewFilter({ ...newFilter, course: newValue.id });
                }
            }}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                    <li key={key} {...optionProps}>
                        {option.formatted_name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required
                    label="Select Course"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
            )}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.formatted_name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        formatted_name: `Request to add the course "${inputValue}"`,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
        />

        {/* Modal for requesting a missing course */}
        <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: '8px',
        }}>
            <h2>Request a Course</h2>
            <TextField
                label="Course Name"
                fullWidth
                value={newCourse.name}
                onChange={(e) => setNewCourse({ ...newCourse, name: e.target.value })}
                margin="normal"
            />
            <TextField
                label="Course Location"
                fullWidth
                value={newCourse.location}
                onChange={(e) => setNewCourse({ ...newCourse, location: e.target.value })}
                margin="normal"
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleCloseModal} sx={{ mr: 2 }}>Cancel</Button>
                <Button variant="contained" onClick={handleSubmitNewCourse}>Submit</Button>
            </Box>
        </Box>
    </Modal>
    </div>
        );

    };

    export default CourseSearch;