import axios from 'axios';

const baseURL = process.env.REACT_APP_BACK_END_URL;
let token = localStorage.getItem('access_token');
const apiClient = axios.create({
    baseURL: baseURL,
    headers: {
        'Authorization': `Bearer ${token}`
    },
});

apiClient.interceptors.response.use(
    response => response, // Pass through successful responses
    async error => {
        console.log("Got error");
        const originalRequest = error.config;

        // Check if the error is due to unauthorized (401) and it's not a retry request
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            
            try {
                // Attempt to refresh the token
                const refreshToken = localStorage.getItem('refresh_token');
                const response = await axios.post(`${baseURL}/token_refresh/`, { refresh: refreshToken });
                
                // Save the new token and retry the original request
                const newToken = response.data.access;
                localStorage.setItem('access_token', newToken);
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                
                return apiClient(originalRequest); // Retry the original request with new token
            } catch (refreshError) {
                // Handle token refresh failure (e.g., redirect to login)
                console.error('Token refresh failed', refreshError);
                // Optionally, redirect the user to the login page
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);
export default apiClient;
