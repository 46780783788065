import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Container, Button, useMediaQuery
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';

import { useTheme } from '@mui/material/styles';
import PreregisterButton from '../components/PreregisterButton';
import AnimatedTitle from '../components/AnimatedTitle';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';
import Spinner from '../components/Spinner';
import axios from 'axios';

const Landing: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile devices
  const [courses, setCourses] = useState([]);
  const baseURL = process.env.REACT_APP_BACK_END_URL;


  const fetchCourses = async () => {
    try {
        const response = await axios.get(`${baseURL}/courses/`, {
        });
        setCourses(response.data.map((course: any) => course.formatted_name));
    } catch (error) {
        console.error("Error fetching courses:", error);
    }
};

useEffect(() => {
  fetchCourses();
}, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, side-by-side on larger screens
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100vw',
          height: isMobile ? 'auto' : '70vh',
          pb: 30,
          pt: 5,
          background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 90%, #f7f7f7),
                 url('/titlebackground.png') center/cover no-repeat`
        }}
      >
        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            padding: { xs: 2, md: 4 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            sx={{
              fontWeight: 'bold',
              color: '#333',
              mb: 2,
            }}
          >
            <AnimatedTitle text="Play More," delay={0.5} /><br /><AnimatedTitle text="Stress Less" delay={2} />
          </Typography>

          <Typography
            variant={isMobile ? 'body1' : 'h5'}
            sx={{
              color: '#555',
              mb: 4,
              px: {xs: 8, md: 0},
            }}
          >
            Stay ahead of the game with instant alerts for available tee times—effortless, hassle-free golfing.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <PreregisterButton />
            <Button
              onClick={() => {
                const element = document.getElementById('how-it-works');
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                padding: '12px 24px',
                fontSize: isMobile ? '12px' : '18px',
                fontWeight: 'bold',
                borderRadius: '50px',
                boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.light,
                  transform: 'scale(1.05)',
                  boxShadow: '0px 8px 20px rgba(0,0,0,0.5)',
                },
              }}
            >
              Learn More
            </Button>
          </Box>
        </Box>

        {/* Image Section */}
        <Box
          sx={{
            flex: 1,
            // backgroundImage: 'url(/persongolfing.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            m: 10,
            height: { xs: '40vh', md: '100%' }, // Adjust image height for mobile
            width: '100%',
            borderRadius: { md: '8px' }, // Add slight rounding for a modern touch
          }}
        ></Box>
      </Box>

        <HowItWorks />

        <Box
        sx={{
          backgroundColor: "#ededed",
        }}
      >
        <Pricing />
        </Box>
        <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: 'white',
          p: 10
        }}
      >
        <Typography
                variant={isMobile ? 'h4' : 'h3'}
                textAlign={"center"}
                sx={{ fontWeight: 700, mb: 2, pb: 5 }}
            >
                New courses added daily
            </Typography>
        <Spinner courses={courses} />
        </Box>
    </Box>
  );
};

export default Landing;
