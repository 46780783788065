import {
    TextField,
    FormControl, MenuItem, Select, InputLabel, Box
} from '@mui/material';
import TimeRangeSlider from '../components/TimeRangeSlider';
import ToggleDays from '../components/DaysOfWeek';
import CourseSearch from './CourseSearch';

interface FilterFormProps {
    newFilter: any;
    setNewFilter: (filter: any) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({
    newFilter,
    setNewFilter
}) => {

    const handleDaysChange = (selected: number[]) => {
        setNewFilter({ ...newFilter, days_of_week: selected.join(',') })
    };

    return (
        <div>
            <CourseSearch newFilter={newFilter} setNewFilter={setNewFilter}/>
            <Box display="flex" justifyContent="space-between" gap={2}>
            <TextField
                required
                margin="dense"
                name="num_of_players"
                label="Number of Players"
                type="number"
                fullWidth
                value={newFilter.num_of_players}
                onChange={(e) => setNewFilter({ ...newFilter, num_of_players: Number(e.target.value) })}
            />
            <FormControl fullWidth>
                <InputLabel id="size-label">Size</InputLabel>
                <Select
                    labelId="size-label"
                    value={newFilter.size}
                    onChange={(e) => setNewFilter({ ...newFilter, size: e.target.value as number })}
                    label="Size"
                >
                    <MenuItem value={-1}>Any</MenuItem>
                    <MenuItem value={9}>9 holes</MenuItem>
                    <MenuItem value={18}>18 holes</MenuItem>
                </Select>
            </FormControl>
            </Box>
            <Box display="flex" justifyContent="space-between" gap={2}>
                <TextField
                    slotProps={{ inputLabel: { shrink: true } }}
                    margin="dense"
                    name="date_start"
                    label="Start Date"
                    type="date"
                    fullWidth
                    value={newFilter.date_start}
                    onChange={(e) => setNewFilter({ ...newFilter, date_start: e.target.value })}
                />
                <TextField
                    slotProps={{ inputLabel: { shrink: true } }}
                    margin="dense"
                    name="date_end"
                    label="End Date"
                    type="date"
                    fullWidth
                    value={newFilter.date_end}
                    onChange={(e) => setNewFilter({ ...newFilter, date_end: e.target.value })}
                />
            </Box>
            <TimeRangeSlider
                timeGte={newFilter.time_gte || '05:00'}
                timeLte={newFilter.time_lte || '19:00'}
                onChange={(gte: string, lte: string) => setNewFilter({ ...newFilter, time_gte: gte, time_lte: lte })}
            />
            <ToggleDays onChange={handleDaysChange} canEdit={true} highlightedDays={newFilter.days_of_week ? newFilter.days_of_week.split(',').map(Number) : []} />

            
        </div>
    );
};

export default FilterForm;
