import React, { useEffect, useState } from 'react';
import apiClient from '../utils/apiClient';
import { Container, TextField, Button, Typography, Box, Avatar, Alert, Snackbar, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Account: React.FC = () => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [referral_code, setReferralCode] = useState('');
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // Fetch user details when component mounts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await apiClient.get('/account/');
                const { first_name, last_name, email, phone_number, status, referral_code } = response.data;
                setFirstName(first_name);
                setLastName(last_name);
                setEmail(email);
                setPhoneNumber(phone_number);
                setReferralCode(referral_code);
            } catch (error: any) {
                setMessage('Failed to load user data');
            }
        };
        fetchUserData();
    }, []);

    const handleCopyReferralCode = () => {
        navigator.clipboard.writeText(referral_code);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/subscription');
    };

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();

        // Basic validation checks
        if (!first_name || !last_name || !email || !phone_number) {
            setMessage('Please fill in all fields');
            return;
        }

        const nameRegex = /^[a-zA-Z]+$/; // Only allows alphabets

        // Validate first name and last name
        if (!nameRegex.test(first_name) || !nameRegex.test(last_name)) {
            setMessage("First name and last name should only contain letters.");
            setIsSuccess(false); // Error alert
            return;
        }

        // Check valid email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage('Please enter a valid email address');
            return;
        }

        // Check valid phone number (basic validation)
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(phone_number)) {
            setMessage('Please enter a valid 10-digit phone number');
            return;
        }

        try {
            const response = await apiClient.put('/account/', { first_name, last_name, email, phone_number });
            
            if (response.data.success) {
                setMessage('Account details updated successfully');
                setIsSuccess(true);
            } else {
                setMessage(response.data.error);
                setIsSuccess(false);
            }
        } catch (error: any) {
            setMessage('Failed to update account details');
            setIsSuccess(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AccountCircleIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    My Account
                </Typography>
                <Box component="form" onSubmit={handleSave} sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="first_name"
                        label="First Name"
                        name="first_name"
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="phone_number"
                        label="Phone Number"
                        name="phone_number"
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        disabled
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            mb: 2,
                            backgroundColor: '#e5f3fd',
                            padding: 1,
                            borderRadius: 1,
                            cursor: 'pointer',
                        }}
                        onClick={handleCopyReferralCode}
                    >
                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            Referral code: <strong>{referral_code}</strong>
                        </Typography>
                        <IconButton edge="end">
                            <ContentCopyIcon />
                        </IconButton>
                    </Box>
                    {message && (
                        <Alert severity={isSuccess ? "success" : "error"} sx={{ width: '100%', mt: 2 }}>
                            {message}
                        </Alert>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Save Changes
                    </Button>
                    <Button
                        onClick={handleRedirect}
                        fullWidth
                        variant="contained"
                        color="info"
                        sx={{ mb: 5 }}
                    >
                        Manage Subscription
                    </Button>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message="Referral code copied to clipboard"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Container>
    );
};

export default Account;
