import React, { useState } from 'react';
import { Button, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Survey from '../components/Survey';
import apiClient from '../utils/apiClient';

const Unsubscribe: React.FC = () => {
    const [submitted, setSubmitted] = useState(false); // Tracks submission state
    const navigate = useNavigate();

    const handlePostSubmit = async () => {
        await apiClient.post('/cancel-subscription/', {});
        setSubmitted(true);
    };


    if (submitted) {
        return (
            <Paper elevation={3} sx={{ maxWidth: 700, mx: 'auto', mt: 5, p: 4, borderRadius: 3, textAlign: 'center' }}>
                <Typography variant="h4" fontWeight="bold" mb={3}>
                    We're sad to see you go 😢
                </Typography>
                <Typography variant="body1" mb={4} color="textSecondary">
                    Thank you for taking the time to share your feedback. Your input helps us improve.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => navigate("/dashboard")} // Redirect to home page
                    sx={{
                        px: 4,
                        py: 1.5,
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        borderRadius: 3,
                    }}
                >
                    Return to Home
                </Button>
            </Paper>
        );
    }

    return (
        <Survey surveyId={1} handlePostSubmit={handlePostSubmit}/>
    );
};

export default Unsubscribe;
