import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

const steps = [
    {
        title: "Select a course",
        description:
            "Pick your preferred golf course from a growing list of options. If your course isn't listed yet, you can easily request it, and we'll add it to our rapidly expanding collection.",
        image: "/select_course.png",
    },
    {
        title: "Set your filters",
        description:
            "Customize your tee time preferences, including the time of day, days of the week, course size, and number of players, to ensure you get notifications that fit your ideal schedule.",
        image: "/set_filter.png",
    },
    {
        title: "Get notified",
        description:
            "Get instant text alerts when a matching tee time becomes available, complete with a direct link to book your spot effortlessly.",
        image: "/get_message.png",
    },
];

const HowItWorks = () => {
    const [activeStep, setActiveStep] = useState(-1);
    const stepRefs = useRef<(HTMLDivElement)[]>([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const index = stepRefs.current.indexOf(entry.target as HTMLDivElement);
                    if (entry.isIntersecting) {
                        setActiveStep(index); // Only set one step as active
                    } else {
                        setActiveStep(-1); // Reset active step when it's not visible
                    }
                });
            },
            {
                root: null,
                rootMargin: "0px",
                threshold: 1, // Trigger when 50% of the element is visible
            }
        );

        // Observe each step element
        stepRefs.current.forEach((ref) => ref && observer.observe(ref));

        return () => {
            // Disconnect observer on cleanup
            observer.disconnect();
        };
    }, []);

    return (
        <div>
            <Typography
                id="how-it-works"
                variant="h3"
                sx={{
                    fontWeight: 700,
                    m: {xs: 2, md: 10},
                    textAlign: {xs: 'center', md: 'left'}
                }}
            >
                Getting Started
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for desktop
                    gap: 4,
                    py: 6,
                    m: {xs: 5, md: 10},
                }}
            >
                {/* Steps with Images */}
                <Box sx={{ flex: 1, maxWidth: { xs: "100%", md: "40%" } }}>
                    {steps.map((step, index) => (
                        <Box
                            key={index}
                            ref={(el: HTMLDivElement) => (stepRefs.current[index] = el)}
                            sx={{
                                mb: { xs: 6, md: 40 }, // Adjust spacing for mobile vs. desktop
                                pb: { xs: 4, md: 20 },
                            }}
                        >
                            {/* Text Content */}
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    mb: 1,
                                    transition: "color 0.3s",
                                    color: activeStep === index ? "primary.main" : "text.primary",
                                }}
                            >
                                Step {index + 1}
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 700,
                                    mb: 1,
                                }}
                            >
                                {step.title}
                            </Typography>
                            <Typography variant="body1">{step.description}</Typography>

                            {/* Image Content */}
                            <Box
                                sx={{
                                    mt: { xs: 3, md: 0 }, // Add margin on mobile
                                    display: { xs: "flex", md: "none" }, // Show image only below text for mobile
                                    justifyContent: "center",
                                }}
                            >
                                <motion.img
                                    src={step.image}
                                    alt={step.title}
                               
                                    style={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                                        opacity: 1
                                    }}
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>

                {/* Right Side: Image for Desktop */}
                <Box
                    sx={{
                        flex: 1,
                        display: { xs: "none", md: "block" }, // Hide for mobile
                        position: "fixed",
                        top: "20%",
                        right: "5%",
                        width: "500px",
                        height: "auto",
                        pointerEvents: "none",
                    }}
                >
                    {steps.map((step, index) => (
                        <motion.img
                            key={index}
                            src={step.image}
                            alt={step.title}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{
                                opacity: activeStep === index ? 1 : 0,
                                y: activeStep === index ? 0 : 50,
                            }}
                            transition={{ duration: 0.5 }}
                            style={{
                                width: "100%",
                                borderRadius: "8px",
                                boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                                position: activeStep === index ? "static" : "absolute",
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </div>
    );
};

export default HowItWorks;
