import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { motion } from "framer-motion";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PreregisterButton from "./PreregisterButton";

const PricingCard = () => {
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.3 } // Trigger when 30% of the element is visible
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", py: 8, color: "black" }}>
            <motion.div
                ref={cardRef}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.8, ease: "easeOut" }}
                style={{
                    background: "linear-gradient(to bottom right, #fff, #ededed)",
                    borderRadius: "16px",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                    padding: "24px",
                    width: "300px",
                    textAlign: "center",
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Basic Plan
                </Typography>
                <Typography
                    variant="h3"
                    sx={{ mt: 2, display: "flex", justifyContent: "center", alignItems: "baseline" }}
                >
                    $9
                    <Typography variant="body1" component="span" sx={{ ml: 0.5, color: "text.secondary" }}>
                        /mo
                    </Typography>
                </Typography>
                <Typography variant="h5" sx={{ textDecoration: "line-through", opacity: 0.6 }}>
                    $18/mo
                </Typography>
                <List sx={{ mb: 4 }}>
                    {[
                        "Up to 10 filters",
                        "Access to 100+ courses",
                        "Unlimited notifications",
                    ].map((feature, index) => (
                        <ListItem
                            key={index}
                            sx={{ py: 0.5 }}
                            component={motion.div}
                            initial={{ opacity: 0, x: -20 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.4, delay: index * 0.2 }}
                        >
                            <ListItemIcon>
                                <CheckCircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                        </ListItem>
                    ))}
                </List>
                <PreregisterButton />
                <Typography variant="caption" sx={{ display: "block", mt: 1, color: "text.secondary" }}>
                    Only available to the first 100 users!
                </Typography>
            </motion.div>
        </Box>
    );
};


//     return (
//         <Box
//             sx={{
//                 maxWidth: 300,
//                 margin: "0 auto",
//                 p: 4,
//                 border: "1px solid",
//                 borderColor: "primary.main",
//                 borderRadius: 4,
//                 boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
//                 textAlign: "center",
//                 backgroundColor: "#fff",
//             }}
//             component={motion.div}
//             initial={{ opacity: 0, y: 50 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.6 }}
//         >
//             {/* Tier Name */}
//             <Typography
//                 variant="h4"
//                 sx={{
//                     fontWeight: 700,
//                     color: "black",
//                     mb: 2,
//                 }}
//             >
//                 Basic Plan
//             </Typography>

//             {/* Price Box */}
//             <Box
//                 sx={{
//                     display: "inline-block",
//                     px: 3,
//                     py: 2,
//                     background: "primary.main",
//                     color: "black",
//                     borderRadius: 4,
//                     fontWeight: 700,
//                     mb: 3,
//                 }}
//             >
//                 <Typography
//                     variant="h3"
//                     component="span"
//                     sx={{ fontSize: "2.5rem", fontWeight: 700 }}
//                 >
//                     $9
//                 </Typography>
//                 <Typography
//                     variant="body1"
//                     component="span"
//                     sx={{
//                         fontSize: "1.2rem",
//                         fontWeight: 400,
//                         marginLeft: 0.5,
//                         verticalAlign: "super",
//                     }}
//                 >
//                     /mo
//                 </Typography>
// <Typography variant="h5" sx={{ textDecoration: "line-through", opacity: 0.6 }}>
//     $18/mo
// </Typography>
//             </Box>

//             {/* Features List */}
// <List sx={{ mb: 4 }}>
//     {[
//         "Up to 10 filters",
//         "Access to 100+ courses",
//         "Unlimited notifications",
//     ].map((feature, index) => (
//         <ListItem
//             key={index}
//             sx={{ py: 0.5 }}
//             component={motion.div}
//             initial={{ opacity: 0, x: -20 }}
//             animate={{ opacity: 1, x: 0 }}
//             transition={{ duration: 0.4, delay: index * 0.2 }}
//         >
//             <ListItemIcon>
//                 <CheckCircleIcon color="primary" />
//             </ListItemIcon>
//             <ListItemText primary={feature} />
//         </ListItem>
//     ))}
// </List>

//             <PreregisterButton/>
//         </Box>
//     );
// };

export default PricingCard;
