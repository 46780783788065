import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar, CssBaseline } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TuneIcon from '@mui/icons-material/Tune';
import PersonIcon from '@mui/icons-material/Person';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';


const drawerWidth = 240;

const SideMenu = ({ isAdmin }: { isAdmin: boolean}) => {
    const theme = useTheme(); // Access the theme to potentially use theme colors
    return (
        <div>
          <CssBaseline />
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: "#212631",
                color: theme.palette.primary.contrastText,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <List>
              {/* Menu Item 1 */}
              <ListItem
                
                component={Link}
                to="/dashboard"
                sx={{
                  backgroundColor: 'none', // Purple background
                  borderRadius: 2, // Rounded corners
                  width: drawerWidth - 40,
                  margin: '10px 15px', // Add some spacing around the menu item
                  '&:hover': {
                    backgroundColor: '#2a303d', // Darker shade on hover
                  },
                }}
              >
                <ListItemIcon>
                  <HomeIcon style={{ color: theme.palette.primary.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Home" sx={{ color: theme.palette.primary.contrastText }}/>
              </ListItem>
    
              {/* Menu Item 2 */}
              <ListItem
                
                component={Link}
                to="/filters"
                sx={{
                  backgroundColor: 'none', // Purple background
                  borderRadius: 2, // Rounded corners
                  width: drawerWidth - 40,
                  margin: '10px 15px', // Add some spacing around the menu item
                  '&:hover': {
                    backgroundColor: '#2a303d', // Darker shade on hover
                  },
                }}
              >
                <ListItemIcon>
                  <TuneIcon style={{ color: theme.palette.primary.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Filters" sx={{ color: theme.palette.primary.contrastText }}/>
              </ListItem>

              <ListItem
                
                component={Link}
                to="/account"
                sx={{
                  backgroundColor: 'none', // Purple background
                  borderRadius: 2, // Rounded corners
                  width: drawerWidth - 40,
                  margin: '10px 15px', // Add some spacing around the menu item
                  '&:hover': {
                    backgroundColor: '#2a303d', // Darker shade on hover
                  },
                }}
              >
                <ListItemIcon>
                  <PersonIcon style={{ color: theme.palette.primary.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Account" sx={{ color: theme.palette.primary.contrastText }}/>
              </ListItem>

              {isAdmin && <ListItem
                
                component={Link}
                to="/admin"
                sx={{
                  backgroundColor: 'none', // Purple background
                  borderRadius: 2, // Rounded corners
                  width: drawerWidth - 40,
                  margin: '10px 15px', // Add some spacing around the menu item
                  '&:hover': {
                    backgroundColor: '#2a303d', // Darker shade on hover
                  },
                }}
              >
                <ListItemIcon>
                  <InsertChartIcon style={{ color: theme.palette.primary.contrastText }} />
                </ListItemIcon>
                <ListItemText primary="Admin" sx={{ color: theme.palette.primary.contrastText }}/>
              </ListItem>}
    
            </List>
          </Drawer>
        </div>
      );
    };
    
    export default SideMenu;
