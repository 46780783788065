import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
    IconButton,
    CardActions,
    Avatar,
    Grid2
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleDays from './DaysOfWeek';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';

interface FilterProps {
    filter: any;
    openEditForm: (filter: any) => void;
    handleDeleteFilter: (filterId: number) => void;
    subscribed: boolean;
}

const FilterComponent: React.FC<FilterProps> = ({ filter, openEditForm, handleDeleteFilter, subscribed }) => {
    const theme = useTheme();
    return (
        <Grid2 key={filter.id}>
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 300,
                width: 300,
                backgroundColor: subscribed ? 'white' : 'lightgrey',
                borderRadius: '20px'
            }} key={filter.id}>
                <CardHeader
                    avatar={
                        subscribed ? <Avatar sx={{ bgcolor: theme.palette.primary.main }} aria-label="recipe">
                            {filter.course_initials}
                        </Avatar> : <LockIcon sx={{ marginRight: 0.5 }} />
                    }
                    title={filter.course_name}
                    subheader={filter.size > 0 ? `${filter.size} holes` : "Any course size"}
                />
                <CardContent>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {`Players: ${filter.num_of_players}`}
                    </Typography>
                    {filter.date_start && filter.date_end && (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {`From ${filter.date_start} to ${filter.date_end}`}
                        </Typography>
                    )}
                    {(filter.time_gte || filter.time_lte) && (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {filter.time_gte ? (filter.time_lte ? `After ${filter.time_gte} and before ${filter.time_lte}` : `After ${filter.time_gte}`) : `Before ${filter.time_lte}`}
                        </Typography>
                    )}
                    {filter.days_of_week && (
                        <ToggleDays onChange={null} canEdit={false} highlightedDays={filter.days_of_week.split(',').map(Number)} />
                    )}
                </CardContent>
                <CardActions sx={{ mt: 'auto', justifyContent: 'space-between' }}>
                    <Button size="small" onClick={() => openEditForm(filter)}>Edit</Button>
                    <IconButton
                        size="small"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteFilter(filter.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid2>
    );
};

export default FilterComponent;
