import React from 'react';
import { Box, Typography } from '@mui/material';

// NotificationBar Component
const NotificationBar = () => {
    return (
        <Box
    sx={{
        backgroundColor: '#FFEB3B', // Bright yellow
        color: '#000', // Black text
        textAlign: 'center',
        py: 1,
        fontSize: '14px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        px: 2,
    }}
>
    <Typography variant="body2">
        Preregister now to secure your spot among the first 100 users and lock in a{' '}
        <Typography
            component="span"
            sx={{
                fontWeight: 'bold',
                fontSize: '16px', // Slightly larger font size for emphasis
                display: 'inline',
            }}
        >
            lifetime 50% discount!
        </Typography>
    </Typography>
</Box>
    );
};

export default NotificationBar;
