import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import apiClient from '../utils/apiClient';
import CourseSearch from '../components/CourseSearch';
import { FilterType } from '../types/FilterType';
import ToggleDays from '../components/DaysOfWeek';
import TimeRangeSlider from '../components/TimeRangeSlider';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

const NewUserSurvey = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [fadeConfetti, setFadeConfetti] = useState(false);
    const [newFilter, setNewFilter] = useState<FilterType>({
        num_of_players: 1,
        course: '',
        date_start: '',
        date_end: '',
        days_of_week: '',
        time_gte: '',
        time_lte: '',
        size: -1,
        user: ''
    });

    const NUM_OF_QUESTIONS = 4

    const handleNext = () => {
        if (step == 1 && newFilter.course == '') {
            alert("Please select a course");
            return;
        }
        if (step < NUM_OF_QUESTIONS) setStep(step + 1);
    };

    const handleBack = () => {
        if (step > 0) setStep(step - 1);
    };

    const handleViewFilters = () => {
        navigate('/filters');
    };

    const handleDaysChange = (selected: number[]) => {
        setNewFilter({ ...newFilter, days_of_week: selected.join(',') })
    };

    const handleCreateFilter = async () => {
        try {
            const response = await apiClient.post('/filters/create/', newFilter);
            if (response.statusText == "Created") {
                setSubmitted(true);
                setShowConfetti(true);
                setTimeout(() => setFadeConfetti(true), 3000);
                setTimeout(() => setShowConfetti(false), 5000);
            } else {
                alert('Error saving survey.');
            }
        } catch (err) {
            console.log('Failed to create filter');
        }
    };

    const renderStep = () => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Typography variant="h6">Welcome to Quick Tee!</Typography>
                        <WavingHandIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                        <Typography>In order to help you find the tee times you want, we are going to ask you a few questions. </Typography>
                    </Box>
                )
            case 1:
                return (
                    <Box>
                        <GolfCourseIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            What is your main golf course?
                        </Typography>
                        <CourseSearch newFilter={newFilter} setNewFilter={setNewFilter} />
                    </Box>
                );

            case 2:
                return (
                    <Box sx={{ textAlign: 'center' }}>
                        <GroupIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            How many people do you usually golf with (including yourself)?
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    setNewFilter({
                                        ...newFilter,
                                        num_of_players: Math.max(1, newFilter.num_of_players - 1),
                                    })
                                }
                                disabled={newFilter.num_of_players <= 1}
                                sx={{ minWidth: '50px', minHeight: '50px', fontSize: 24 }}
                            >
                                -
                            </Button>
                            <Typography
                                variant="h5"
                                sx={{ mx: 3, width: '50px', textAlign: 'center', fontWeight: 'bold' }}
                            >
                                {newFilter.num_of_players}
                            </Typography>
                            <Button
                                variant="outlined"
                                onClick={() =>
                                    setNewFilter({
                                        ...newFilter,
                                        num_of_players: Math.min(4, newFilter.num_of_players + 1),
                                    })
                                }
                                disabled={newFilter.num_of_players >= 4}
                                sx={{ minWidth: '50px', minHeight: '50px', fontSize: 24 }}
                            >
                                +
                            </Button>
                        </Box>
                    </Box>
                );

            case 3:
                return (
                    <Box>
                        <CalendarTodayIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            What days of the week do you prefer to golf?</Typography>
                        <ToggleDays onChange={handleDaysChange} canEdit={true} highlightedDays={newFilter.days_of_week ? newFilter.days_of_week.split(',').map(Number) : []} />
                    </Box>
                );

            case 4:
                return (
                    <Box>
                        <AccessTimeIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            What times of the day do you like to golf?</Typography>
                        <TimeRangeSlider
                            timeGte={newFilter.time_gte || '05:00'}
                            timeLte={newFilter.time_lte || '19:00'}
                            onChange={(gte: string, lte: string) => setNewFilter({ ...newFilter, time_gte: gte, time_lte: lte })}
                        />
                    </Box>
                );

            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 500,
                mx: 'auto',
                mt: 4,
                p: 3,
                border: '1px solid #ccc',
                borderRadius: 2,
                textAlign: 'center',
                height: '400px',
                display: 'flex', // Enable Flexbox
                flexDirection: 'column', // Stack children vertically
                justifyContent: 'space-between', // Space content evenly
            }}
        >
            {submitted ? (
                <>
                    {showConfetti && (
                        <div
                            style={{
                                position: 'absolute', // Take the confetti out of the normal flow
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                pointerEvents: 'none', // Ensure it doesn't block interactions
                                opacity: fadeConfetti ? 0 : 1,
                                transition: 'opacity 2s ease',
                            }}
                        >
                            <Confetti />
                        </div>
                    )}
                    <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Congratulations!
                    </Typography>
                    <Typography>
                        You've successfully set up your first filter! You'll receive a text message as soon as an opening becomes available.
                        Let's take a look at your filters:
                    </Typography>

                    <Button variant="contained" onClick={handleViewFilters}>
                        View Filters
                    </Button>
                </>
            ) : (
                <>
                    {/* Content for the current step */}
                    <Box>
                        {renderStep()}
                    </Box>

                    {/* Navigation Buttons at the bottom */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" disabled={step === 0} onClick={handleBack}>
                            Back
                        </Button>
                        {step < NUM_OF_QUESTIONS ? (
                            <Button variant="contained" onClick={handleNext}>
                                Next
                            </Button>
                        ) : (
                            <Button variant="contained" color="success" onClick={handleCreateFilter}>
                                Submit
                            </Button>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default NewUserSurvey;
