// src/AuthContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";

// Define Context Type
interface AuthContextType {
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    isTokenExpired: () => boolean;
    refreshToken: () => Promise<string>;
}

// Create Context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Provide Context
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const baseURL = process.env.REACT_APP_BACK_END_URL;


    // Login Function
    const login = async (username: string, password: string): Promise<void> => {
        const response = await axios.post(`${baseURL}/token_obtain/`, { username, password }, { withCredentials: true });
        console.log(response)
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
    };

    const refreshToken = async () => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            const response = await axios.post(`${baseURL}/token_refresh/`, {
                refresh: refreshToken,
            });
            // Update access token
            localStorage.setItem('access_token', response.data.access);
            return response.data.access;  // Return the new access token
        } catch (error) {
            console.error('Token refresh failed', error);
            return null;
        }
    };

    const isTokenExpired = () => {
        const token = localStorage.getItem('access_token');
        if(token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if(decodedToken.exp) {
                return decodedToken.exp < currentTime;  // Compare expiration time
            }
        }
        return true;
    };

    // Logout Function
    const logout = async () => {
        localStorage.clear();
    };

    return (
        <AuthContext.Provider value={{ login, logout, isTokenExpired, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom Hook to use Auth Context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
