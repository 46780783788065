import { useState, useEffect } from "react";
import { styled } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Typography } from "@mui/material";

const DAYS = [
  { key: "monday", label: "M" },
  { key: "tuesday", label: "T" },
  { key: "wednesday", label: "W" },
  { key: "thursday", label: "T" },
  { key: "friday", label: "F" },
  { key: "saturday", label: "S" },
  { key: "sunday", label: "S" },
];
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
  },
  '& .MuiToggleButtonGroup-grouped:first-of-type': {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
  },
}));


const StyledToggle = styled(ToggleButton)(({ theme }) => ({
  color: "#692B7C",
  '&.Mui-selected': {
    color: "white",
    background: theme.palette.secondary.main,
  },
  '&:hover': {
    borderColor: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
  },
  '&:hover.Mui-selected': {
    borderColor: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
  },
  minWidth: 32,
  maxWidth: 32,
  height: 32,
  textTransform: "unset",
  fontSize: "0.75rem",
  margin: 7,
}));

const ToggleDays = ({ highlightedDays, canEdit, onChange }: { highlightedDays: number[], canEdit: boolean, onChange: any }) => {
  const [days, setDays] = useState(highlightedDays);
  useEffect(() => {
    setDays(highlightedDays);
  }, [highlightedDays]);

  const changeDay = (selected: number[]) => {
    setDays(selected);
    onChange(selected);
  };

  return (

    <>
      {canEdit && <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>Days of the Week</Typography>
        <StyledToggleButtonGroup
          size="small"
          arial-label="Days of the week"
          value={days}
          onChange={(event, value) => changeDay(value)}
        >
          {DAYS.map((day, index) => (
            <StyledToggle key={day.key} value={index} aria-label={day.key}>
              {day.label}
            </StyledToggle>
          ))}
        </StyledToggleButtonGroup>
      </Box>}
      {!canEdit && <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
      >
        {DAYS.map((day, index) => (
          <StyledToggle key={day.key} value={index} aria-label={day.key}>
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>}
    </>
  );
};

export default ToggleDays;
