import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import apiClient from '../utils/apiClient';
import { Button, useMediaQuery } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled, useTheme } from '@mui/material/styles';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const Subscribe = ({ withTrial = false }: { withTrial?: boolean}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleSubscribe = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await apiClient.post('/create-checkout-session/', { trial: withTrial});

      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      await stripe!.redirectToCheckout({
        sessionId: response.data.id,
      });
    } catch (error) {
      console.error("Error in creating checkout session:", error);
      setErrorMessage("Error subscribing. Please refresh and try again.");
    }
    setLoading(false);
  };

  const FancyButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#ff9800',
    margin: 20,
    color: '#fff',
    padding: '12px 24px',
    fontSize: isMobile ? '12px' : '18px',
    fontWeight: 'bold',
    borderRadius: '50px',
    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: '#fb8c00',
      transform: 'scale(1.05)',
      boxShadow: '0px 8px 20px rgba(0,0,0,0.5)',
    },
  }));

  return (
    <div>
      {errorMessage && (
        <p style={{ color: 'red', marginBottom: '1em' }}>{errorMessage}</p>
      )}
      <FancyButton
      onClick={handleSubscribe}
      disabled={loading}
      endIcon={<ShoppingCartIcon />} // Adds an icon at the end
    >
      {loading ? "Processing..." : "Subscribe Now"}
    </FancyButton>
    </div>
  );
};

export default Subscribe;
