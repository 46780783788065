import { useEffect, useState } from 'react';
import apiClient from '../utils/apiClient';
import { Box, Typography, Checkbox, FormControlLabel, Button, CircularProgress, Paper, Grid } from '@mui/material';

const Survey = ({ surveyId, handlePostSubmit }: { surveyId: number, handlePostSubmit: () => void}) => {
    const [survey, setSurvey] = useState<any>(null); // Holds survey data
    const [loading, setLoading] = useState(true);   // Handles loading state
    const [responses, setResponses] = useState<{ [key: number]: string[] }>({}); // Tracks user responses by question ID

    useEffect(() => {
        // Fetch survey data from the backend
        const fetchSurvey = async () => {
            try {
                const response = await apiClient.get(`/survey/${surveyId}/`);
                setSurvey(response.data);
                console.log(survey);
            } catch (error) {
                console.error("Error fetching survey:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSurvey();
    }, []);

    const handleCheckboxChange = (questionId: number, choice: string) => {
        setResponses((prev) => {
            const prevResponses = prev[questionId] || [];
            const updatedResponses = prevResponses.includes(choice)
                ? prevResponses.filter((res) => res !== choice)
                : [...prevResponses, choice];
            return { ...prev, [questionId]: updatedResponses };
        });
    };

    const handleSubmit = async () => {
        try {
            const responsePayload = {
                survey_id: survey.id,
                answers: responses,
            };
            await apiClient.post('/submit-answer/', responsePayload);
            handlePostSubmit();
        } catch (error) {
            console.error("Error submitting survey:", error);
            alert("Failed to submit. Please try again.");
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!survey) {
        return (
            <Box textAlign="center" mt={4}>
                <Typography variant="h6">No survey available at the moment.</Typography>
            </Box>
        );
    }

    

    return (
        <Paper elevation={3} sx={{ maxWidth: 700, mx: 'auto', mt: 5, p: 4, borderRadius: 3 }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center" mb={3}>
            {survey.name}
        </Typography>
        <Typography variant="body1" textAlign="center" mb={4} color="textSecondary">
            {survey.description}
        </Typography>

        {survey.questions.map((question: any) => (
            <Box key={question.id} mb={4}>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                    {question.text}
                </Typography>
                <Grid container spacing={1}>
                    {question.choices.map((choice: string, index: number) => (
                        <Grid item xs={12} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={responses[question.id]?.includes(choice) || false}
                                        onChange={() => handleCheckboxChange(question.id, choice)}
                                    />
                                }
                                label={choice}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    background: '#f9f9f9',
                                    padding: '8px 16px',
                                    borderRadius: 2,
                                    boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
                                    transition: 'all 0.3s ease',
                                    '&:hover': {
                                        background: '#f0f0f0',
                                    },
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        ))}

        <Box textAlign="center" mt={4}>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                disabled={Object.keys(responses).length !== survey.questions.length}
                sx={{
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    borderRadius: 3,
                }}
            >
                Submit
            </Button>
        </Box>
    </Paper>
    );
};

export default Survey;
