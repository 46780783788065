import React, { useState } from 'react';
import { Box, Slider, Typography } from '@mui/material';

interface TimeRangeSliderProps {
    timeGte: string;
    timeLte: string;
    onChange: (gte: string, lte: string) => void;
}

// Slider component for selecting start and end times
const TimeRangeSlider: React.FC<TimeRangeSliderProps> = ({ timeGte, timeLte, onChange }) => {
    const timeToMinutes = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTimeAMPM = (minutes: number) => {
        const hours24 = Math.floor(minutes / 60);
        const mins = minutes % 60;
        
        const period = hours24 >= 12 ? 'PM' : 'AM';
        const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, ensuring 12 is used for 0
        const formattedMins = String(mins).padStart(2, '0'); // Pad single-digit minutes with 0
    
        return `${hours12}:${formattedMins} ${period}`;
    };

    const minutesToTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
    };

    const [timeRange, setTimeRange] = useState<number[]>([
        timeToMinutes(timeGte),
        timeToMinutes(timeLte)
    ]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        const [newGte, newLte] = newValue as number[];
        setTimeRange([newGte, newLte]);
        onChange(minutesToTime(newGte), minutesToTime(newLte));
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" sx={{  margin: 'auto', padding: 4 }}>
            <Typography gutterBottom>Start/End Time</Typography>
            <Slider
                value={timeRange}
                onChange={handleChange}
                min={300}
                max={1140}
                step={15} // 15-minute intervals
                valueLabelDisplay="auto"
                marks={[
                    { value: 300, label: '5:00 AM' },
                    { value: 1140, label: '7:00 PM' },
                ]}
                valueLabelFormat={(value) => minutesToTimeAMPM(value)}
            />
        </Box>
    );
};

export default TimeRangeSlider;
