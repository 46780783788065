import { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, CardContent, Divider, CircularProgress } from '@mui/material';
import apiClient from '../utils/apiClient';
import Subscribe from '../components/Subscribe';
import { useNavigate } from 'react-router-dom';

const Subscription = () => {
    const [planName, setPlanName]: any = useState('');
    const [monthlyCost, setMonthlyCost]: any = useState('');
    const [memberSince, setMemberSince]: any = useState('');
    const [nextPaymentDate, setNextPaymentDate]: any = useState('');
    const [status, setStatus]: any = useState('');
    const [trialEnd, setTrialEnd]: any = useState('');
    const [hasSubscription, setHasSubscription] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const navigate = useNavigate();

    const fetchUserData = async () => {
        try {
            const response = await apiClient.get('/subscription/');

            const { plan_name, monthly_cost, member_since, next_payment_date, trial_end, status } = response.data;
            setPlanName(plan_name);
            setMonthlyCost(monthly_cost);
            setMemberSince(member_since);
            setNextPaymentDate(next_payment_date);
            setTrialEnd(trial_end);
            setStatus(status);
            setHasSubscription(status == 'active' || status == 'trialing');
        } catch (error: any) {
            console.log("No subscription found");
        } finally {
            setLoadingPage(false);
        }
    };

    const handleUnsubscribe = async () => {
        navigate('/unsubscribe');
    };

    useEffect(() => {
        fetchUserData();
    }, []);


    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <Card sx={{ width: '100%', maxWidth: 600, padding: 3, boxShadow: 3 }}>

                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Subscription Details
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    {loadingPage ? (<Box display="flex" justifyContent="center" my={1}>
                        <CircularProgress size={50} color="inherit" />
                    </Box>
                    ) : (<div>
                        {status ? <div>
                            <Box display="flex" justifyContent="space-between" my={1}>
                                <Typography variant="h6">Plan Name:</Typography>
                                <Typography>{planName}</Typography>
                            </Box>


                            <Box display="flex" justifyContent="space-between" my={1}>
                                <Typography variant="h6">Subscription Status:</Typography>
                                <Typography style={{ textTransform: 'capitalize' }}>{status}</Typography>
                            </Box>

                            {hasSubscription &&
                                <Box display="flex" justifyContent="space-between" my={1}>
                                    <Typography variant="h6">Monthly Cost:</Typography>
                                    <Typography>{monthlyCost}</Typography>
                                </Box>
                            }

                            {hasSubscription &&
                                <Box display="flex" justifyContent="space-between" my={1}>
                                    <Typography variant="h6">Member Since:</Typography>
                                    <Typography>{memberSince}</Typography>
                                </Box>
                            }
                            {hasSubscription &&
                                <Box display="flex" justifyContent="space-between" my={1}>
                                    <Typography variant="h6">Next Payment Date:</Typography>
                                    <Typography>{nextPaymentDate}</Typography>
                                </Box>
                            }
                        </div> :
                            <Typography display="flex" justifyContent="center">You haven't subscribed yet. Click below to get started!</Typography>}


                        <Divider sx={{ my: 2 }} />
                        {hasSubscription ? (
                            <Button
                                variant="contained"
                                fullWidth
                                color="error"
                                onClick={handleUnsubscribe}
                            >
                                Unsubscribe
                            </Button>
                        ) :
                            (
                                <Box display="flex" justifyContent="center" my={1}>
                                    <Subscribe />
                                </Box>
                            )}
                    </div>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default Subscription;
