import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo_small.png';
import { useNavigate } from 'react-router-dom';
import NotificationBar from './NotificationBar';

interface TopMenuBarProps {
  showMenuIcon?: boolean;
  onToggleSideMenu?: () => void;
  isLoggedIn: boolean;
}

const TopMenuBar: React.FC<TopMenuBarProps> = ({ showMenuIcon = false, onToggleSideMenu, isLoggedIn }) => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    navigate(isLoggedIn ? "/dashboard" : "/"); // Navigate appropriately based on login status
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    window.location.href = '/login';
  };

  const handlePreregister = () => {
    navigate('/register', { state: { origin: 'preregister' } });
  };

  const handleDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: (theme) => theme.palette.primary.main }}>
      {!isLoggedIn && <NotificationBar />}
      <Toolbar>
        {/* Conditionally render the menu icon */}
        {showMenuIcon && (
          <IconButton color="inherit" edge="start" aria-label="menu" onClick={onToggleSideMenu}>
            <MenuIcon />
          </IconButton>
        )}

        {/* Logo and Title */}
        <Box sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={handleLogoClick}>
          <img
            src={logo}
            alt="Quick Tee Logo"
            style={{ width: '30px', height: '30px', marginRight: '10px' }}
          />
          <Typography variant="h6">Quick Tee</Typography>
        </Box>

        {/* Buttons for logged-in and logged-out states */}
        <Box sx={{ marginLeft: 'auto' }}>
          {isLoggedIn ? (
            <>
              <Button color="inherit" onClick={handleDashboard}>Dashboard</Button>
              <Button color="inherit" onClick={handleLogout}>Logout</Button>
            </>
          ) : (
            <>
              <Button
                onClick={handlePreregister}
                sx={{
                  backgroundColor: 'secondary.main', // Custom background color (e.g., blue)
                  color: '#fff',              // Text color
                  borderRadius: '12px',       // Rounded corners
                  padding: '4px 8px',        // Adjust padding for a better look
                  '&:hover': {
                    backgroundColor: 'secondary.dark', // Darker shade for hover effect
                  },
                }}
              >
                Preregister
              </Button>
              <Button color="inherit" onClick={handleLogin}>Login</Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenuBar;
