
const AnimatedTitle = ({ text, delay }: { text: string, delay: number }) => {
  const words = text.split(" ");

  return (
    <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
      {words.map((word, idx) => (
        <span key={idx} style={{ opacity: 0, animation: `fadeIn 1s ease forwards`, animationDelay: `${(idx * 0.5) + delay}s` }}>
          {word}&nbsp;
        </span>
      ))}
    </div>
  );
}

export default AnimatedTitle;
