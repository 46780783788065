import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button, Typography, Box, Grid2, Avatar, Link, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as RouterLink } from 'react-router-dom'; // Import Link from React Router

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await login(email, password);
            console.log("navigating to /dashboard");
            navigate("/dashboard");
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                console.log(error)
                if (error.code === 'ERR_BAD_REQUEST') {
                    setMessage('Email or password is incorrect');
                } else {
                    setMessage('Login failed');
                }
            } else {
                setMessage('An unexpected error occurred.');
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                {message && (
                    <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
                        {message}
                    </Alert>
                )}

                <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleLogin}
                    >
                        Sign In
                    </Button>
                    <Grid2 container spacing={2}>
                        <Grid2 size={5}>
                            <Typography variant="body2" color="textSecondary">
                                Forgot password?
                            </Typography>
                        </Grid2>
                        <Grid2 size={7}>
                            <Typography variant="body2" color="textSecondary">
                                {"Don't have an account? "}
                                <Link component={RouterLink} to="/register" variant="body2">
                                    {"Sign Up"}
                                </Link>
                            </Typography>

                        </Grid2>
                    </Grid2>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
