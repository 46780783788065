import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import apiClient from '../utils/apiClient';
import CourseSearch from '../components/CourseSearch';
import { FilterType } from '../types/FilterType';
import { useNavigate } from 'react-router-dom';

const PreregisterSurvey = () => {
    const navigate = useNavigate();

    const [newFilter, setNewFilter] = useState<FilterType>({
        num_of_players: 1,
        course: '',
        date_start: '',
        date_end: '',
        days_of_week: '',
        time_gte: '',
        time_lte: '',
        size: -1,
        user: ''
    });
    const [courseRequestCount, setCourseRequestCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false)
    };
    
    const handleCreateFilter = async () => {
        try {
            const response = await apiClient.post('/filters/create/', newFilter);
            navigate('/dashboard');
        } catch (err) {
            console.log('Failed to create filter');
        }
    };

    const onNewCourseRequest = () => {
        setCourseRequestCount((prevCount) => prevCount + 1);
        if (courseRequestCount + 1 >= 3) {
            navigate('/dashboard');
        } else {
            setOpenModal(true)
        }
    }

    const finishNewCourseRequest = () => {
        navigate('/dashboard');
    }

    return (
        <div>
        <Box
            sx={{
                maxWidth: 500,
                mx: 'auto',
                mt: 4,
                p: 3,
                border: '1px solid #ccc',
                borderRadius: 2,
                textAlign: 'center',
                height: '400px',
                display: 'flex', // Enable Flexbox
                flexDirection: 'column', // Stack children vertically
                justifyContent: 'space-between', // Space content evenly
            }}>
            <Box>
                <GolfCourseIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    What is your main golf course? 
                </Typography>
                <Typography sx={{ mb: 1 }}>

                If you can't find your course, type out the name to request the course.
                </Typography>

                <CourseSearch newFilter={newFilter} setNewFilter={setNewFilter} onNewCourseRequest={onNewCourseRequest} />


                <Button variant="contained" color="success" onClick={handleCreateFilter}>
                    Submit
                </Button>

            </Box>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4,
            boxShadow: 24,
            borderRadius: '8px',
        }}>
            <h2>Would you like to request another course?</h2>
            <p>You can request up to three courses</p>
           
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={finishNewCourseRequest} sx={{ mr: 2 }}>No</Button>
                <Button variant="contained" onClick={handleCloseModal}>Yes</Button>
            </Box>
        </Box>
    </Modal>
    </div>
    );
};

export default PreregisterSurvey;
